.heading {
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Gowun Batang', serif;
}

.h1 {
    font-size: 150%;
    font-weight: 600;
}

.h2 {
    font-size: 88%;
}

.social-icons {
    margin-top: 3px;
    margin-left: -3px;
}

.social-icon {
    display: inline-block;
    text-align: center;
    width: 32px;
}