.app {
  /* text-align: center; */
}

.main-content {
  padding: 0% 22%;
  background-color: #2c292c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}
